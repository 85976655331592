import React, { PureComponent } from 'react';
import './Hiw.css';


export default class Hiw extends PureComponent {
  render(){
    return(
      <div
        id={'hiw'}
        className={'hiw-container'}>
        <h1 className={'hiw-title'}>{'How it works'}</h1>
        <img
          className={'hiw-img'}
          src={'images/hiw.svg'}
          alt={'how it works'}/>
      </div>
    );
  }
}
