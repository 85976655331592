import React, { Component } from 'react';
import * as ReactGA from 'react-ga';
import { isAndroid, isIOS } from 'react-device-detect';
import Countdown from 'react-countdown';
import { releaseDate } from '../const';
import './DownloadButton.css';

export default class DownloadLink extends Component {
  constructor(props){
    super(props);
    this.state = { isReleased: new Date() > releaseDate };
  }

  release = () => {
    this.setState({ isReleased: true });
  }

  clickPlayStore = () => {
    ReactGA.event({ category: 'download', action: 'clicked Play Store' });
  }

  clickAppStore = () => {
    ReactGA.event({ category: 'download', action: 'clicked App Store' });
  }

  render(){
    return (
      <div
        className={'store-container'}
        style={isIOS ? { top: '65vh' } : undefined}>
        {!this.state.isReleased && (
          <div className={'countdown'}>
            <div>{'release'}</div>
            <Countdown
              date={releaseDate}
              onComplete={this.release}/>
          </div>
        )}
        {this.state.isReleased && !isIOS && (
          <a
            href={'https://play.google.com/store/apps/details?id=com.avlonder.whoozin'}
            onClick={this.clickPlayStore}>
            <img
              className={'store'}
              src={'/images/play-store.png'}
              alt={'Play Store'}/>
          </a>
        )}
        {this.state.isReleased && !isAndroid && (
          <a
            href={'https://apps.apple.com/us/app/id1527402713'}
            onClick={this.clickAppStore}>
            <img
              className={'store'}
              src={'/images/app-store.png'}
              alt={'App Store'}/>
          </a>
        )}
      </div>
    )
  }
}