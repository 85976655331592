import React, { PureComponent } from 'react';
import * as ReactGA from 'react-ga';
import './EmailButton.css';

export default class EmailButton extends PureComponent {
  openEmail = () => {
    window.open(`mailto:${this.props.email}?subject=${this.props.subject}`);
    ReactGA.event({ category: 'email', action: `clicked "${this.props.text}"` });
  }

  render(){
    return(
      <button
        className={'email-button'}
        onClick={this.openEmail}>
        <div className={'email-button-text'}>
          {this.props.text}
        </div>
        <i className={'fa fa-envelope email-button-icon'}/>
      </button>
    );
  }
}
