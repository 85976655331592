import React,  { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import About from './pages/About';
import Home from './pages/Home';
import { initGA } from './utils/GoogleAnalytics';

export default function App() {
  useEffect(() => {
    const hasConsent = getCookieConsentValue('GA');
    if(hasConsent === "true") initGA();
  }, []);

  return (
    <>
      <Router>
        <Navbar/>
        <Switch>
          <Route path='/' exact component={Home}/>
          <Route path='/About' component={About}/>
        </Switch>
        <Footer/>
        <CookieConsent
          location={'bottom'}
          buttonText={`I'm in!`}
          cookieName={'GA'}
          style={{ backgroundColor: '#924e9e', fontFamily: 'Roboto', fontSize: 12 }}
          buttonStyle={{ color: '#fff', backgroundColor: '#0f9d58', borderRadius: 5 }}
          onAccept={initGA}>
          Do you want to help us improve Whoozin by setting up analytical cookies?
        </CookieConsent>
      </Router>
    </>
  );
}
