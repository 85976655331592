import React, { PureComponent } from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './HeroSection.css';

const valueProps = [
  {
    image: '/images/value-prop/friends.jpg',
    text: `Hang out with friends`
  },
  /*
  {
    image: '/images/value-prop/new-people.jpg',
    text: `Meet new people`
  },
  {
    image: '/images/value-prop/break-routines.jpg',
    text: `Break your routines`
  },
  */
  {
    image: '/images/value-prop/dont-plan.jpg',
    text: `Don't plan upfront`
  },
  {
    image: '/images/value-prop/chat-less-do-more.jpg',
    text:
      <>
        {`Chat less, `}
        <span className={'nobr'}>{`do more`}</span>
      </>
  },
  {
    image: '/images/value-prop/level-up.jpg',
    text:
      <>
        {`Level up `}
        <span className={'nobr'}>{`& unlock`}</span>
      </>
  },
  {
    image: '/images/value-prop/free.jpg',
    text: `Check for promos`
  }
];

export default class HeroSection extends PureComponent {
  render(){
    return (
      <Fade
        duration={5000}
        transitionDuration={1000}
        autoplay
        infinite
        pauseOnHover={false}
        arrows={false}>
        {valueProps.map(({image, text}, i) =>
          <div
            className={'img-container'}
            key={i}>
            <img
              src={image}
              alt={text}/>
            <h1 className={'header'}>{text}</h1>
          </div>
        )}
      </Fade>
    )
  }
}
