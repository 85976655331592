import React, { useState } from 'react';
import * as ReactGA from 'react-ga';
import { HashLink } from 'react-router-hash-link';
import './Navbar.css';

export default function Navbar() {

  const [mobileMenuIsShown, setMobileMenuIsShown] = useState(false);
  const [hasBackground, setHasBackground] = useState(false);

  const clickMobileMenu = () => {
    setMobileMenuIsShown(!mobileMenuIsShown);
  }

  const clickWhoozin = () => {
    setMobileMenuIsShown(false);
    ReactGA.event({ category: 'navigation', action: 'clicked "Whoozin"' });
  }

  const clickAbout = () => {
    setMobileMenuIsShown(false);
    ReactGA.event({ category: 'navigation', action: 'clicked "about"' });
  }

  const clickDownload = () => {
    setMobileMenuIsShown(false);
    ReactGA.event({ category: 'navigation', action: 'clicked "download"' });
  }

  const clickHIW = () => {
    setMobileMenuIsShown(false);
    ReactGA.event({ category: 'navigation', action: 'clicked "hiw"' });
  }

  const clickPartners = () => {
    setMobileMenuIsShown(false);
    ReactGA.event({ category: 'navigation', action: 'clicked "partners"' });
  }

  const clickFAQ = () => {
    setMobileMenuIsShown(false);
    ReactGA.event({ category: 'navigation', action: 'clicked "faq"' });
  }

  const changeBackground = () => setHasBackground(window.scrollY >= 80);
  window.addEventListener('scroll', changeBackground);

  return (
    <nav className={hasBackground ? 'navbar active' : 'navbar'}>
      <div className={'navbar-container'}>
        <HashLink
          smooth
          to='/#top'
          className={'navbar-logo'}
          onClick={clickWhoozin}>
          <img
            className={'navbar-logo'}
            src={'/images/logo.png'}
            alt={'app logo'}/>
          Whoozin
        </HashLink>
        <div
          className='menu-icon'
          onClick={clickMobileMenu}>
          <i className={mobileMenuIsShown ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={mobileMenuIsShown ? 'nav-menu active' : 'nav-menu'}>
          <li>
            <HashLink
              to={'/About#top'}
              className={'nav-links'}
              onClick={clickAbout}>
              About
            </HashLink>
          </li>
          <li>
            <HashLink
              smooth
              to={'/#download'}
              className={'nav-links'}
              onClick={clickDownload}>
              Download
            </HashLink>
          </li>
          <li>
            <HashLink
              smooth
              to='/#hiw'
              className={'nav-links'}
              onClick={clickHIW}>
              How it works
            </HashLink>
          </li>
          <li>
            <HashLink
              smooth
              to='/#partners'
              className={'nav-links'}
              onClick={clickPartners}>
              Partners
            </HashLink>
          </li>
          <li>
            <HashLink
              smooth
              to='/#faq'
              className={'nav-links'}
              onClick={clickFAQ}>
              FAQ
            </HashLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}
