import React, { PureComponent } from 'react';
import * as ReactGA from 'react-ga';
import './Footer.css';

export default class Footer extends PureComponent {
  clickFB = () => {
    ReactGA.event({ category: 'social', action: 'clicked Facebook' });
  }

  clickIG = () => {
    ReactGA.event({ category: 'social', action: 'clicked Instagram' });
  }

  render(){
    return(
      <div className={'footer-container'}>
        <div className={'whoozin-rights'}>
            Whoozin
          <sup className={'rights'}>
            {' © ®'}
          </sup>
        </div>
        <div className={'socials'}>
          <a
            className={'social-icon-link facebook'}
            href={'https://www.facebook.com/Whoozin-105063898230956'}
            onClick={this.clickFB}>
            <i className={'fab fa-facebook'}/>
          </a>
          <a
            className={'social-icon-link instagram'}
            href={'https://www.instagram.com/whoozin_stagram/'}
            onClick={this.clickIG}>
            <i className={'fab fa-instagram'}/>
          </a>
        </div>
      </div>
    );
  }
}