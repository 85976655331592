import React, { PureComponent } from 'react';
import * as ReactGA from 'react-ga';
import AnimateHeight from 'react-animate-height';
import EmailButton from './EmailButton';
import './Faq.css';


const faqItems = [
  {
    question: `How do I invite someone?`,
    answer:
      <>
        <p>
          First things first... you should be around. Whoozin wants to optimize last-minute planning and,
          as such, even users like you with brilliant ideas for activities should be open to proposals
          from others.
        </p>
        <p>
          The most straightforward way to invite someone is by having a look at the map and tapping a friend who's
          around too.
        </p>
        <p>
          Alternatively, you can go to the swipe list (the three bars), where you can invite friends and contacts
          that aren't necessarily around by swiping them to the right.
        </p>
        <p>
          Both approaches, the map and the swipe list, open a form asking short answers to the questions what? where? and when?.
        </p>
        <p>
          After creating your event, you can still invite more friends by tapping/swiping them.
        </p>
        <p>
          Note that only users who're around receive a notification about your invitation.
        </p>
    </>
  },
  {
    question: `How do I earn experience points?`,
    answer:
    <p>
      There are many many ways to earn xp. We won't discuss them all here. The most important ones are by creating friendships
      -- especially friendships with new users who've entered your invitation code! -- and by participating in events
      -- especially if the event is based on a promo!. Note that you only receive your xp after effectively
      arriving at the event. Every time you earn xp, an animation is shown in the lower-left corner of the app.
    </p>
  },
  {
    question: `How do I consume a promo?`,
    answer:
    <p>
      To consume a promo, you should create or accept an event that's based on a promo. Similar to inviting friends, this can
      be done by either tapping the promo, which is depicted by a star, on the map or by swiping the promo item to the right in
      the swipe list. Once your event has enough participants -- the default is two, including you -- you can move to the place
      that's giving the promo and each of the participants can show the promo screen to one of the employees over there. This screen
      can be accessed from both the map and the swipe list. The former has a yellow-star button when tapping the event box displayed
      on the top of the map, whereas the latter permanently shows the yellow-star button in the lower-right corner once you've arrived.
    </p>
  },
  {
    question: `What does "being around" mean?`,
    answer:
    <>
      <p>
        Being around means that you've got some amount of free time that you'ld like to spend together with your friends.
        Being around is the primary step for everything useful in this app. As long as you're not around, you can't see
        other friends who're around and you can't receive event-related notifications.
      </p>
      <p>
        People don't like to send invitations to friends who're rejecting them by either not responding or saying they're busy.
        Tapping "I'm around" is our simplistic -- yet effective -- firewall for stalkers who've no interest in hanging out with you.
      </p>
      <p>
        You're around as long as the entered amount of free time isn't over and you've not confirmed an event. The presence of your
        avatar in the upper-left corner of the map indicates if you're currently in around mode or committed to an event. By
        tapping the avatar, you can change your filters (action radius, mood and amount of free time) or turn off around mode.
        By long-pressing the avatar, you can take a shortcut and immediately stop being around.
      </p>
    </>
  },
  {
    question: `What are alter egos?`,
    answer:
    <p>
      Alter egos are statistics about your personality when it comes to hanging out with friends. Are you the alpha male that likes
      bringing his friends together? Are you creative? Are you someone who meets up with many different friends? You get more info
      about each alter ego by long-pressing the corresponding bar. Once a bar -- a so-called ego meter -- is filled, you unlock the
      associated alter ego and get a huge experience boost. Mind that the alter-ego stats are time-dependent, meaning that they can
      reduce after a long period of inactivity.
    </p>
  },
  {
    question: `Who can see my personal information?`,
    answer:
    <>
      <p>
        We can't emphasize enough that privacy and safety are of utmost importance to Whoozin. And we're probably sacrifising many
        "easy users" because of it as it implies that our users are not allowed to have a public profile and meet up with strangers.
        We believe that existing social connections are a much safer and more stable way of building our user base and that organically
        hanging out with second-degree friends is probably even a more interesting way of getting to know new people.
      </p>
      <p>
        We don't display your phone number, gender or birthdate to anyone else then yourself.
        Obviously, your username and profile picture are used to help friends recognize you. But only friends can see them.
        We're not uploading your contacts, so even your contacts don't get access to your username or profile pic.
      </p>
    </>
  },
  {
    question: `Who can see my location?`,
    answer:
    <p>
      Only friends who're around can see your location and only when you are around yourself. Moreover, your location is only updated
      while the app is in the foreground. Upon tapping a friend on the map, you get to see when his location was most recently updated.
    </p>
  },
  {
    question: `Why do you need my phone number and contacts?`,
    answer:
    <p>
      To protect you against identity fraud. We verify your phone number by means of a one-time password.
      If a user is not in your contacts, the warning "not in contacts" is shown below his username.
    </p>
  },
  {
    question: `Why can't I add more friends?`,
    answer:
    <p>
      To protect you against yourself. Most people don't want their whole Facebook friend list or contact list to know
      exactly where they are. We want you to think carefully about who you add to your Whoozin friends. The more you use Whoozin and
      get more experience -- both litteraly and in terms of experience points --, the more friends you can add.
    </p>
  },
  {
    question: `Why can't I chat with other participants?`,
    answer:
    <p>
      We believe that chat is overused these days and for many applications, such as event planning, can be replaced by dedicated features
      that are more efficient, better structured and more focused. If you have the feeling that you're missing a chat function, please
      contact us and explain us what you need it for, such that we can think about a feature that fills in your needs in one of
      our next releases.
    </p>
  }
]


class FaqItem extends PureComponent {
  constructor(props){
    super(props);
    this.state = { isExpanded: false };
  }

  toggle = () => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }), this.ga);
  }

  ga = () => {
    if(this.state.isExpanded){
      ReactGA.event({ category: 'faq', action: `expanded "${this.props.question}"` });
    }
  }

  render(){
    return (
      <div className={'faq-item-container'}>
        <button
          className={'faq-question'}
          onClick={this.toggle}>
          {this.props.question}
          <i className={this.state.isExpanded ? "fas fa-caret-up" : "fas fa-caret-down"}/>
        </button>
        <AnimateHeight
          duration={500}
          height={this.state.isExpanded ? 'auto' : 0}>
          <div className={'faq-answer'}>
            {this.props.answer}
          </div>
        </AnimateHeight>
      </div>
    )
  }
}


export default class Faq extends PureComponent {
  constructor(props){
    super(props);
    this.state = { allFaqAreShown: false };
  }

  toggle = () => {
    this.setState(prevState => ({ allFaqAreShown: !prevState.allFaqAreShown }), this.ga);
  }

  ga = () => {
    if(this.state.allFaqAreShown){
      ReactGA.event({ category: 'faq', action: 'clicked "show more"' });
    }
  }

  render(){
    return(
      <div
        id={'faq'}
        className={'faq-container'}>
        <h1 className={'faq-title'}>{'FAQ'}</h1>
        {faqItems.slice(0, 5).map(item => <FaqItem {...item} key={item.question}/>)}
        <AnimateHeight
          duration={500}
          height={this.state.allFaqAreShown ? 'auto' : 0}>
          {faqItems.slice(5).map(item => <FaqItem {...item} key={item.question}/>)}
        </AnimateHeight>
        <button
          className={'faq-more'}
          onClick={this.toggle}>
          {this.state.allFaqAreShown ? 'show less' : 'show more'}
        </button>
        <EmailButton
          text={'ask a question'}
          email={'info@whoozin.app'}
          subject={'question'}/>
      </div>
    );
  }
}