import React from 'react';
import './Logo.css';

export default function Logo() {
  return(
    <div className={'logo-container'}>
      <img
        className={'app-logo'}
        src={'/images/logo.png'}
        alt={'app logo'}/>
      <div>
        <div className={'app-title'}>
          Whoozin
        </div>
      </div>
    </div>
  );
}