import React from 'react';
import { Parallax } from 'react-parallax';
import { Fade } from 'react-awesome-reveal';
import YouTube from 'react-youtube';
import { releaseDate } from '../const';
import './About.css';

import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(AdvancedFormat);

const releaseDateString = dayjs(releaseDate).format('MMM Do YYYY');

const sections = [
  {
    title: `Back to freedom`,
    body:
      <>
        Now that the vaccination campaign is clearly paying off,
        we can start thinking about reclaiming our freedom and see people again in real life. Roaring
        twenties, here we come!  Don’t worry if you haven’t made any plans yet. Whoozin is the ideal
        way to arrange <b>last-minute meetups</b> with your friends. No need to be stuck to a tight
        schedule like we used to before. If there's one silver lining we should remember from the
        pandemic, it's that the empty social agenda originally gave us some well-deserved rest.
        Whoozin enables us to do what we want, when, where and with who we want to. No need to rush
        from one place to another and definitely no need to be lonely. It's a new way of living.
      </>
  },
  {
    title: `One simple promise`,
    body:
      <>
        Whoozin is built on one simple promise: <b>online friends are thrilled to hang out with you</b>.
        You don’t have to put yourself through the mental barrier of randomly texting tens of friends
        before finding someone who hasn't got plans either. Instead, simply let your Whoozin network
        know that you're around and in return you get to see which of your friends are around and which
        nearby events you're invited to. If you don't find anything interesting, just propose an activity
        yourself. Whoozin encourages politeness and positiveness, so there's no need to be afraid to get
        rejected. We're all on the same page: looking for fun together. And those who aren't will
        automatically drop off. Pinky swear.
      </>
  },
  {
    title: `Chat less, do more`,
    body:
      <>
        Our main goal is to get you on the move instead of attached to your smartphone screen.
        We want to help you break your weekly routines, discover new places, make new friends, ...
        and we want this service to be as smooth and efficient as possible.
        Chats are slow and time-consuming, and typically lose focus very quickly.
        That's why we provide you with a <b>streamlined use flow</b> to get you next to your
        friends in just a few taps, <b>without the need for a chat conversation</b>.
      </>,
    side:
      <div className={'video-container'}>
        <YouTube
          className={'video'}
          videoId={'2y_eKy_SiaU'}/>
        <figcaption className={'video-caption'}>
          Barney shows how efficient mobile communication actually can be.
        </figcaption>
      </div>
  },
  {
    title: `It's all about experience`,
    body:
      <>
        Whoozin has a lot to offer but you first have to prove yourself worthy! Being regularly
        active on the platform, organizing and participating in events, creating new friendships
        and groups, and bringing new people to the platform all increase your experience. As a
        user of this app, you gradually <b>gather xp and evolve through different user levels</b>,
        which allows you to unlock more resources and more features.
      </>
  },
  {
    title: `We're releasing!`,
    body:
      <>
        If you're living in <b>Belgium</b> and you have a Belgian mobile phone number,
        you can join Whoozin starting from <b>{releaseDateString}</b>. The app could still have
        some teething problems, but hey ... Rome wasn't built in a day either. We've got
        many awesome ideas where we could go next with this app, but we need your <b>feedback</b>.
        So, please drop some of your findings in the stores or use the app's built-in feedback option
        to help us improve our product and make the world a more social place. We've only just started!
      </>
  }
];

export default function About() {
  return(
    <Parallax
      blur={3}
      bgImage={window.innerWidth > 900 ? '/images/map-horo.png' : '/images/map-vert.png'}
      bgImageAlt={'background'}
      strength={500}>
      <>
      <div className={'overlay'}/>
      <div className={'container'}>
        {sections.map((section) =>
          <Fade
            triggerOnce
            duration={2000}>
            <div
              className={'section-row-container'}
              key={section.title}>
              <div className={'section-container'}>
                <h2 className={'section-title'}>{section.title}</h2>
                <div className={'section-body'}>{section.body}</div>
              </div>
              <div className={'side-container'}>{section.side}</div>
            </div>
          </Fade>
        )}
      </div>
      </>
    </Parallax>
  );
}