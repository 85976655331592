import React, { PureComponent } from 'react';
import * as ReactGA from 'react-ga';
import { Slide } from 'react-slideshow-image';
import EmailButton from './EmailButton';
import './Partners.css';

const partnerItems = [
  {
    logo: '/images/partners/PorterHouse.jpg',
    name: `Porter House`,
    url: 'https://www.porterhouse.be'
  },
  {
    logo: '/images/partners/Twitch.jpg',
    name: `Twitch`,
    url: 'https://www.twitch-gent.be'
  },
  {
    logo: '/images/partners/TheNoodles.jpg',
    name: `The Noodles`,
    url: 'https://www.thenoodles.be'
  },
  {
    logo: '/images/partners/JillesBier&Burgers.jpg',
    name: `Jilles Bier & Burgers`,
    url: 'https://www.jilles.be'
  },
  {
    logo: '/images/partners/ShamrockIrishPub.jpg',
    name: `Shamrock Irish Pub`,
    url: 'https://www.facebook.com/pages/category/Irish-Pub/Shamrock-Irish-Pub-114288637010796'
  },
  {
    logo: '/images/partners/Dogs&Drinks.jpg',
    name: `Dogs & Drinks`,
    url: 'https://www.dogsanddrinks.be'
  },

  {
    logo: '/images/partners/JackPremiumBurgers.jpg',
    name: 'Jack Premium Burgers',
    url: 'https://www.jackburgers.be'
  },
  {
    logo: '/images/partners/CafeRomain.jpg',
    name: `Café Romain`,
    url: 'https://www.facebook.com/pages/category/Cafe/Caf%C3%A9-Romain-302073456481804'
  },
  {
    logo: '/images/partners/Balls&Glory.jpg',
    name: `Balls & Glory`,
    url: 'https://ballsnglory.be'
  }
];

class PartnerItem extends PureComponent {
  click = () => {
    ReactGA.event({ category: 'partner', action: `clicked ${this.props.name}` });
  }

  render(){
    return (
      <div className={'partner-item-container'}>
        <a
          href={this.props.url}
          onClick={this.click}>
          <img
            className={'partner-item-logo'}
            src={this.props.logo}
            alt={this.props.name}/>
        </a>
      </div>
    )
  }
}

export default class Partners extends PureComponent {
  render(){
    return(
      <div
        id={'partners'}
        className={'partners-container'}>
        <h1 className={'partners-title'}>{'Our partners'}</h1>
        <div className={'partners-subtitle'}>
          Our partners are very generous and creative. <br/>
          Have a look at the map for what they may be offering you right now!
        </div>
        <Slide
          className={'partners-slideshow'}
          slidesToShow={3}
          slidesToScroll={1}
          autoplay={false}
          canSwipe={false}
          arrows
          infinite>
          {partnerItems.map(item => <PartnerItem {...item} key={item.name}/>)}
        </Slide>
        <EmailButton
          text={'become a partner'}
          email={'info@whoozin.app'}
          subject={'partner'}/>
      </div>
    );
  }
}
