import * as ReactGA from 'react-ga';

export function initGA() {
  /*
    Note: You must create custom environment variables beginning with REACT_APP_.
    Any other variables except NODE_ENV will be ignored to avoid accidentally
    exposing a private key on the machine that could have the same name.
    Changing any environment variables will require you to restart the development
    server if it is running.
  */
  if(process.env.NODE_ENV === 'production' && process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview('Home');
  }
};
