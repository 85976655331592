import React from 'react';
import Faq from '../components/Faq';
import HeroSection from '../components/HeroSection';
import Hiw from '../components/Hiw';
import Logo from '../components/Logo';
import Download from '../components/Download';
import Partners from '../components/Partners';

export default function Home() {
  return (
    <>
      <HeroSection/>
      <Logo/>
      <Download/>
      <Hiw/>
      <Partners/>
      <Faq/>
    </>
  );
}
