import React, { PureComponent } from 'react';
import DownloadLink from './DownloadButton';
import './Download.css';


export default class Mockup extends PureComponent {
  render(){
    return(
      <div
        id={'download'}
        className={'mockup-container'}>
        <div className={'mockup-left-container'}>
          <div className={'findout-container'}>
            <div className={'findout'}>Find out ...</div>
            <div className={'findout indent'}>what's going on</div>
            <div className={'findout indent'}>who's around</div>
            <div className={'findout indent'}>who's in</div>
          </div>
          <DownloadLink />
        </div>
        <img
          className={'mockup-img'}
          src={'images/mockup.jpg'}
          alt={'mockup'}/>
      </div>
    );
  }
}
